<template>
  <div class="container" style="padding: 10px">
    <div class="columns is-centered">
      <div class="column is-12">
        <img src="../../assets/logo.svg">
      </div>
    </div>
    <div class="columns is-centered">
      <a @click="$ml.change('ru')" title="Русский" style="margin-right: 5px">
        <img src="../../assets/ru.png">
      </a>
      <a @click="$ml.change('en')" title="English">
        <img src="../../assets/en.png">
      </a>
    </div>
    <form autocomplete="off" @submit.prevent="login" novalidate>
      <b-loading :is-full-page="false" v-model="formLoading" :can-cancel="false"></b-loading>
      <div class="columns is-centered">
        <div class="column is-4">
          <b-field :label="$ml.get('newpass_new_password')" label-position="on-border"
                   :message="'password' in errors ? errors['password'] : $ml.get('newpass_new_password_message')"
                   :type="'password' in errors ? 'is-danger' : ''">
            <b-input autocomplete="nope" type="password" v-model="form.password" icon="lock"></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-4">
          <b-field :label="$ml.get('newpass_confirm_password')" label-position="on-border"
                   :message="'confirm_password' in errors ? errors['password'] : ''"
                   :type="'confirm_password' in errors ? 'is-danger' : ''">
            <b-input autocomplete="nope" type="password" v-model="form.confirm_password" icon="lock"></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-4">
          <ul class="horizontal">
            <li>
              <b-button :disabled="!submitEnabled" type="is-info" native-type="submit">
                {{ $ml.get('newpass_set_password') }}
              </b-button>
            </li>
            <li style="padding: 10px 5px 0 60px">
              <router-link :to="{ path: `/signup` }">{{ $ml.get('login_signup') }}</router-link>
            </li>
            <li style="padding: 10px 0 0 0">
              |
            </li>
            <li style="padding: 10px 0 0 5px">
              <router-link :to="{ path: `/login` }">{{ $ml.get('login_login') }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import router from "@/router";
import Vue from "vue";

export default {
  data() {
    return {
      form: {
        password: '',
        confirm_password: '',
        code: '',
      },
      errors: {},
      formLoading: false,
    }
  },
  computed: {
    submitEnabled() {
      return this.form.password.length >= 6 && this.form.confirm_password === this.form.password
    }
  },
  methods: {
    login: function () {
      this.formLoading = true
      Vue.prototype.$http.post(process.env.VUE_APP_API_HOST + 'auth/newpass', {user: this.form}).then(resp => {
        router.push('/login?np=t');
      }).catch(error => {
        this.errors = error.response.data.result
      }).finally(() => {
        this.formLoading = false
      })
    },
  },
  mounted() {
    if (this.$route.query.cnf) {
      this.form.code = this.$route.query.cnf
    }
  }
}
</script>

<style scoped>
img {
  max-width: 300px;
}
</style>